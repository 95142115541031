import { graphql } from "gatsby"
import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import Navbar from "../components/Navbar/Navbar"
import PagesContext from "../contexes/PagesContext"
import getNamedPartials from "../partials"
import H3 from "../partials/Headers/H3"
import { respond } from "../_respond"
import "./remark.css"

const ContentWrapper = styled.article`
  text-align: center;
  background: #fff;
  padding: 2rem 4rem;

  ${respond.xs`
  padding: 0;
`}

  ${respond.sm`
  padding: 0;
`}
`
const Heading = styled.div`
  padding-top: 2rem;
  margin: 0 auto;
`

const StyleLink = styled.div`
  margin-top: 2rem;
  display: grid;
  justify-content: center;
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  opacity: 0.9;

  & ul {
    list-style: none;
    margin: 0 auto;
    padding: 0 !important;
    width: 400px;
  }

  & li {
    display: inline-block;
  }

  & li:not(:last-child)::after {
    content: " || ";
  }

  & a {
    padding: 0.75rem;
    color: #333;
  }
`

export default function GridNavigationTemplate(props) {
  /*   const [showModal, setShowModal] = useState(true) */

  const { data } = props
  const { frontmatter, htmlAst } = data.page
  const partials = getNamedPartials()
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: partials,
  }).Compiler

  const filePath = data.page.fileAbsolutePath.substring(
    data.page.fileAbsolutePath.lastIndexOf("/data/"),
    data.page.fileAbsolutePath.length,
  )

  return (
    <>
      <Fragment>
        <Helmet title={frontmatter.title} />{" "}
        <PagesContext.Provider
          value={{
            all: data,
            current: {
              frontmatter: frontmatter,
              filePath: filePath,
            },
          }}
        >
          {/* <LoginStateContextProviderider> */}
          <Fragment>
            <Navbar />
            <ContentWrapper className="scrolling-destination-course-grid">
              <Heading>
                <H3> {frontmatter.title} </H3>
              </Heading>
              <StyleLink>
                <ul>
                  <li>
                    <a href="/tekijat">Tekijät</a>
                  </li>
                  <li>
                    <a
                      href="/usein-kysytyt-kysymykset"
                      aria-label="usein kysytyt kysymykset"
                    >
                      UKK
                    </a>
                  </li>
                </ul>
              </StyleLink>
              {renderAst(htmlAst)}
            </ContentWrapper>
          </Fragment>
          {/* </LoginStateContextProviderider>{" "} */}
        </PagesContext.Provider>
      </Fragment>
      {/*   </animated.div> */}
    </>
  )
}

export const pageQuery = graphql`
  query ($path: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
        subTitle
        banner
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
            topic
          }
        }
      }
    }
  }
`
